// Dependencies
import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// Styles
import { AgendaWrapper } from './Agenda.styled'

// Components
import Events from './Events'

// Context & Helpers
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getMicroCopy } from '../../helpers/microcopy'

const Agenda = ({ dates, widget = false }) => {
  const { state } = useContext(MicroCopyContext)
  const pastDates = dates
    .filter((date) => date.timestamp <= new Date().getTime())
    .sort((a, b) => b.timestamp - a.timestamp)

  const upcomingDates = dates
    .filter((date) => date.timestamp > new Date().getTime())
    .sort((a, b) => a.timestamp - b.timestamp)

  let monthsLabels =
    getMicroCopy({ key: `events.monthsLabels`, data: state?.value }) || ''
  monthsLabels = monthsLabels.split(',')

  return (
    <div className="content">
      <AgendaWrapper>
        <Events
          {...{
            events: widget ? upcomingDates.slice(0, 3) : upcomingDates,
            title: getMicroCopy({
              key: `events.upcomingLabel`,
              data: state?.value,
            }),
            timeLabel: getMicroCopy({
              key: `events.timeLabel`,
              data: state?.value,
            }),
            addressLabel: getMicroCopy({
              key: `events.addressLabel`,
              data: state?.value,
            }),
            locationLabel: getMicroCopy({
              key: `events.locationLabel`,
              data: state?.value,
            }),
            monthsLabels: monthsLabels,
            noEventsMessage: getMicroCopy({
              key: `events.noEventsMessage`,
              data: state?.value,
            }),
            hideDetailsLabel: getMicroCopy({
              key: `events.hideDetailsLabel`,
              data: state?.value,
            }),
            showDetailsLabel: getMicroCopy({
              key: `events.showDetailsLabel`,
              data: state?.value,
            }),
            showMoreLabel: getMicroCopy({
              key: `events.showMoreLabel`,
              data: state?.value,
            }),
          }}
        />
        {!widget && pastDates.length > 0 && (
          <Events
            {...{
              events: pastDates,
              title: getMicroCopy({
                key: `events.pastLabel`,
                data: state?.value,
              }),
              timeLabel: getMicroCopy({
                key: `events.timeLabel`,
                data: state?.value,
              }),
              addressLabel: getMicroCopy({
                key: `events.addressLabel`,
                data: state?.value,
              }),
              locationLabel: getMicroCopy({
                key: `events.locationLabel`,
                data: state?.value,
              }),
              monthsLabels: monthsLabels,
              hideDetailsLabel: getMicroCopy({
                key: `events.hideDetailsLabel`,
                data: state?.value,
              }),
              showDetailsLabel: getMicroCopy({
                key: `events.showDetailsLabel`,
                data: state?.value,
              }),
              showMoreLabel: getMicroCopy({
                key: `events.showMoreLabel`,
                data: state?.value,
              }),
            }}
          />
        )}
        {widget && (
          <AniLink
            paintDrip
            hex="#ffe5cb"
            duration={1}
            className="button"
            to={'/agenda'}
          >
            <span>
              {getMicroCopy({ key: `ctas.seeEvents`, data: state?.value })}
            </span>
          </AniLink>
        )}
      </AgendaWrapper>
    </div>
  )
}

// Components PropTypes
Agenda.propTypes = {
  sectionTitle: PropTypes.string,
  sectionCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      location: PropTypes.shape({
        title: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        map: PropTypes.shape({
          map: PropTypes.string,
        }),
      }),
      info: PropTypes.shape({
        info: PropTypes.string,
      }),
      date: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ).isRequired,
  widget: PropTypes.bool,
}

export default Agenda
