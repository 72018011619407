// Dependencies
import styled from 'styled-components'

// Constants
import { colors } from '../../constants/colors'
import {
  type18,
  type20,
  type14,
  type25,
  playfairDisplay,
  type50,
  workSans,
} from '../../constants/fonts'
import { transition } from '../../constants/general'
import { sizesMax } from '../../constants/breakpoints'

export const AgendaWrapper = styled.div`
  width: calc(100% / 11 * 11);

  > div {
    width: 100%;
    margin-bottom: 40px;

    padding-top: 10px;

    &:not(:last-child) {
      padding-bottom: 30px;
    }
  }

  li {
    padding: 20px;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    color: ${colors.white};
    background: ${colors.black};

    display: flex;
    flex-direction: row;

    .event {
      &__date {
        margin-right: 20px;
        margin-top: -16px;
        strong {
          ${type50}
          ${playfairDisplay}
                    line-height: 1;
          color: ${colors.cream};
          text-transform: uppercase;
          text-align: center;
          font-style: normal !important;
        }
        strong span {
          display: block;
          ${type25}
          text-align: center;
          margin: 0 auto;
          font-style: italic !important;
        }
      }

      &__info {
        width: 100%;

        &__show-more {
          display: block;
          width: 100%;
          ${type14}
          ${workSans}
            line-height: 1;
          font-weight: bold;
          color: ${colors.cream};
          margin-top: 15px;
          cursor: pointer;
          ${transition}

          &:hover {
            color: ${colors.white};
          }
        }

        &__message {
          p {
            display: inline-block;
            ${type18}
            ${workSans}
            line-height: 1;
            font-weight: bold;
            color: ${colors.white};
            margin-bottom: 0;
          }
        }

        &__title {
          p {
            display: inline-block;
            ${type20}
            ${workSans}
            line-height: 1;
            font-weight: bold;
            color: ${colors.cream};
            margin-bottom: 16px;

            @media screen and (max-width: ${sizesMax.tablet}) {
              margin-bottom: 11px;
            }
          }
        }
        &__time,
        &__location,
        &__extra {
          strong {
            font-style: italic;
            font-weight: bold;
            color: ${colors.white};
          }
          small {
            display: block;
            color: ${colors.white};
            ${type14}
            line-height: 1.3;
          }
        }
        &__map {
          margin-top: 20px;
          iframe {
            width: 100%;
            height: 40vw;
            max-height: 200px;
          }
        }
      }
    }
  }
`
