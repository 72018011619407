// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Event = ({ event, labels }) => {
  const day = event.date.split('/')[0]
  const month = event.date.split('/')[1].split(' ')[0]
  const time = event.date.split(' ')[1]
  const monthName = labels.monthsLabels[parseInt(month, 10) - 1]

  const [expanded, setExpanded] = useState(false)

  return (
    <li className="event">
      <div className="event__date">
        <strong>
          {day}
          <span>{monthName.split('').slice(0, 3).join('')}</span>
        </strong>
      </div>
      <div className="event__info">
        <div className="event__info__title">
          <p>{event.title}</p>
        </div>
        {time && (
          <div className="event__info__time">
            <small>
              <strong>{labels.timeLabel}:</strong> {time}
            </small>
          </div>
        )}
        {expanded ? (
          <>
            {event.location && event.location.address && (
              <div className="event__info__location">
                <small>
                  <strong>{labels.locationLabel}:</strong>{' '}
                  {event.location.title}
                </small>
                <small>
                  <strong>{labels.addressLabel}:</strong>{' '}
                  {event.location.address}
                </small>
              </div>
            )}

            {event.info && (
              <div className="event__info__extra">
                <br />
                <small>{event.info.info}</small>
              </div>
            )}
            {event.location && event.location.map && event.location.map.map && (
              <div className="event__info__map">
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.location.map.map,
                  }}
                />
              </div>
            )}
            {((event.location && event.location.address) ||
              event.info ||
              (event.location &&
                event.location.map &&
                event.location.map.map)) && (
              <a
                className="event__info__show-more"
                href=""
                onClick={(e) => {
                  e.preventDefault()
                  setExpanded(false)
                }}
              >
                {labels.hideDetailsLabel}
              </a>
            )}
          </>
        ) : (
          <>
            {((event.location && event.location.address) ||
              event.info ||
              (event.location &&
                event.location.map &&
                event.location.map.map)) && (
              <a
                className="event__info__show-more"
                href=""
                onClick={(e) => {
                  e.preventDefault()
                  setExpanded(true)
                }}
              >
                {labels.showDetailsLabel}
              </a>
            )}
          </>
        )}
      </div>
    </li>
  )
}

const Events = ({
  events,
  title,
  timeLabel,
  addressLabel,
  locationLabel,
  monthsLabels,
  noEventsMessage,
  showDetailsLabel,
  hideDetailsLabel,
  showMoreLabel,
  hideShowMore,
}) => {
  const LIMIT = 5
  const [limit, setLimit] = useState(LIMIT)

  return (
    <div>
      <h3>{title}</h3>
      {events.length > 0 ? (
        <ul>
          {events.slice(0, limit).map((event, i) => {
            return (
              <Event
                key={`${title}_${i}`}
                {...{
                  event,
                  labels: {
                    monthsLabels,
                    timeLabel,
                    addressLabel,
                    locationLabel,
                    showDetailsLabel,
                    hideDetailsLabel,
                  },
                }}
              />
            )
          })}
        </ul>
      ) : (
        <ul>
          <li className="event">
            <div className="event__info">
              <div className="event__info__message">
                <p>{noEventsMessage}</p>
              </div>
            </div>
          </li>
        </ul>
      )}
      {!hideShowMore && limit < events.length && (
        <button className="button" onClick={() => setLimit(limit + LIMIT)}>
          <span>{showMoreLabel}</span>
        </button>
      )}
    </div>
  )
}

// Components PropTypes
Events.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      location: PropTypes.shape({
        title: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        map: PropTypes.shape({
          map: PropTypes.string,
        }),
      }),
      info: PropTypes.shape({
        info: PropTypes.string,
      }),
      date: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
  addressLabel: PropTypes.string.isRequired,
  locationLabel: PropTypes.string.isRequired,
  monthsLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  noEventsMessage: PropTypes.string.isRequired,
  showDetailsLabel: PropTypes.string.isRequired,
  hideDetailsLabel: PropTypes.string.isRequired,
  showMoreLabel: PropTypes.string.isRequired,
  hideShowMore: PropTypes.bool,
}
Events.defaultProps = {
  hideShowMore: false,
}

export default Events
